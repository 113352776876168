/**
 * *Rôle : Loader before get Data Audio
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
import '../../../styles/skeleton.scss';

const LoadingPlaceHolderSlider = () => {
	return (
		<>
			{/* HEADER */}
			<div className="w-full mb-16 px-2 flex items-center justify-center animate-pulse">
				<div className="w-5/12 flex items-center justify-start">
					<span className="skeleton-box h-7 w-full inline-block"></span>
				</div>
			</div>

			<div className="w-full p-0 flex flex-wrap">
				{/* ITEM 1 */}
				<div className="w-full md:w-1/2 lg:w-1/3 p-2 animate-pulse">
					<div className="py-14 flex flex-col items-center justify-center relative w-full bg-white overflow-hidden card translate-3d-none-after card translate-3d-none-after rounded-2xl border border-gray-200">
						<div className="skeleton-box h-3 w-4/6 inline-block mb-2"></div>
						<div className="skeleton-box h-3 w-2/6 block"></div>
					</div>
				</div>
				{/* ITEM 2 */}
				<div className="hidden md:flex md:w-1/2 lg:w-1/3 p-2 animate-pulse">
					<div className="py-14 flex flex-col items-center justify-center relative w-full bg-white overflow-hidden card translate-3d-none-after card translate-3d-none-after rounded-2xl border border-gray-200">
						<div className="skeleton-box h-3 w-4/6 inline-block mb-2"></div>
						<div className="skeleton-box h-3 w-2/6 block"></div>
					</div>
				</div>
				{/* ITEM 2 */}
				<div className="hidden lg:flex lg:w-1/3 p-2 animate-pulse">
					<div className="py-14 flex flex-col items-center justify-center relative w-full bg-white overflow-hidden card translate-3d-none-after card translate-3d-none-after rounded-2xl border border-gray-200">
						<div className="skeleton-box h-3 w-4/6 inline-block mb-2"></div>
						<div className="skeleton-box h-3 w-2/6 block"></div>
					</div>
				</div>
			</div>

			<div className="w-full mt-8 p-0 flex flex-wrap">
				{/* ITEM 1 */}
				<div className="w-full md:w-1/2 lg:w-1/3 p-2 animate-pulse">
					<div className="py-14 flex flex-col items-center justify-center relative w-full bg-white overflow-hidden card translate-3d-none-after card translate-3d-none-after rounded-2xl border border-gray-200">
						<div className="skeleton-box h-3 w-4/6 inline-block mb-2"></div>
						<div className="skeleton-box h-3 w-2/6 block"></div>
					</div>
				</div>
				{/* ITEM 2 */}
				<div className="hidden md:flex md:w-1/2 lg:w-1/3 p-2 animate-pulse">
					<div className="py-14 flex flex-col items-center justify-center relative w-full bg-white overflow-hidden card translate-3d-none-after card translate-3d-none-after rounded-2xl border border-gray-200">
						<div className="skeleton-box h-3 w-4/6 inline-block mb-2"></div>
						<div className="skeleton-box h-3 w-2/6 block"></div>
					</div>
				</div>
				{/* ITEM 2 */}
				<div className="hidden lg:flex lg:w-1/3 p-2 animate-pulse">
					<div className="py-14 flex flex-col items-center justify-center relative w-full bg-white overflow-hidden card translate-3d-none-after card translate-3d-none-after rounded-2xl border border-gray-200">
						<div className="skeleton-box h-3 w-4/6 inline-block mb-2"></div>
						<div className="skeleton-box h-3 w-2/6 block"></div>
					</div>
				</div>
			</div>

			<div className="w-full mt-8 p-0 flex flex-wrap">
				{/* ITEM 1 */}
				<div className="w-full md:w-1/2 lg:w-1/3 p-2 animate-pulse">
					<div className="py-14 flex flex-col items-center justify-center relative w-full bg-white overflow-hidden card translate-3d-none-after card translate-3d-none-after rounded-2xl border border-gray-200">
						<div className="skeleton-box h-3 w-4/6 inline-block mb-2"></div>
						<div className="skeleton-box h-3 w-2/6 block"></div>
					</div>
				</div>
				{/* ITEM 2 */}
				<div className="hidden md:flex md:w-1/2 lg:w-1/3 p-2 animate-pulse">
					<div className="py-14 flex flex-col items-center justify-center relative w-full bg-white overflow-hidden card translate-3d-none-after card translate-3d-none-after rounded-2xl border border-gray-200">
						<div className="skeleton-box h-3 w-4/6 inline-block mb-2"></div>
						<div className="skeleton-box h-3 w-2/6 block"></div>
					</div>
				</div>
				{/* ITEM 2 */}
				<div className="hidden lg:flex lg:w-1/3 p-2 animate-pulse">
					<div className="py-14 flex flex-col items-center justify-center relative w-full bg-white overflow-hidden card translate-3d-none-after card translate-3d-none-after rounded-2xl border border-gray-200">
						<div className="skeleton-box h-3 w-4/6 inline-block mb-2"></div>
						<div className="skeleton-box h-3 w-2/6 block"></div>
					</div>
				</div>
			</div>
		</>
	);
};

export default LoadingPlaceHolderSlider;

/**
 * Page Audio
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 *
 */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { audio } from '../redux/audio/actionAudio';
import Layout from '../components/body/layout';
import Seo from '../components/seo';
import axios from 'axios';
import Container from '../components/body/Container';
import Loader from '../components/functional/Loader';
import H2 from '../components/functional/H2';
import ContainerWithSideBarre from '../components/body/ContainerWithSideBarre';
import ContentLeft from '../components/body/ContentLeft';
import flowers from '../images/svg/flowers-bg.svg';
import ContentRight from '../components/body/ContentRight';
import LoadingPlaceHolder from '../components/functional/LoadingPlaceHolder/LoadingPlaceHolderAudio';
import SideBar from '../components/marketing/sideBar/SideBar';
import StickyBox from 'react-sticky-box';
import { Link } from '@reach/router';

const Coran = () => {
	//Function Sort Audio By alphabetical => Title mp3
	const sortArray = (sourceArray) => {
		const sortByName = (a, b) => a.title.localeCompare(b.title, 'en', { numeric: true });
		return sourceArray.sort(sortByName);
	};

	const dispatch = useDispatch();

	const [listeCoran, setListeCoran] = useState([]);
	const [currentCategorie, setCurrentCategorie] = useState(null);
	const [currentTitle, setCurrentTitle] = useState(null);

	sortArray(listeCoran); // Trie les data par ordre alphabétique en fonction de l'url mp3 du titre Audio

	useEffect(() => {
		/* Management State Current Cat by URL */
		//  function getParams(location) {
		//    const searchParams = new URLSearchParams(window.location.search);
		//    return {
		//      categoryByPath: searchParams.get('cat' || '',)
		//    };
		//  }

		const params = window.location.search
			.replace('?cat=', '')
			.replace(/[+]/g, ' ')
			.replace(/&lsquo%3B/g, "'")
			.replace(/&rsquo;/g, "'")
			.replace(/&lsquo;/g, "'")
			.replace(/&rsquo%3B/g, "'");
		// console.log(params)
		// const params = getParams(window.location.search.replace("?cat=", "").replace(/[+]/g, " ").replace(/&rsquo;/g, "'").replace(/&lsquo;/g, "'").replace(/&rsquo%3B/g, "'"));

		// console.log(params)

		if (params !== '') {
			setCurrentCategorie(params);
		}
	}, []);

	useEffect(() => {
		/* Management State Current Title by URL */
		function getParams() {
			const searchParams = new URLSearchParams(window.location.search);
			return {
				titleByPath: searchParams.get('title' || '')
			};
		}
		const params = getParams(window.location.search);

		if (params !== '') {
			setCurrentTitle(params.titleByPath);
		}
	}, []);

	const checkLocalStorageAge = (date) => {
		const today = Date.now();
		const timeDifference = today - date;

		const daysDifference = timeDifference / (1000 * 3600 * 24);
		if (daysDifference >= 1) {
			localStorage.removeItem(`liste_audio_coran`);
			localStorage.removeItem(`liste_audio_storageDate_coran`);
			// console.log('start Get Audio')
			axios
				.get(
					`https://us-central1-dourousnet-3fcb0.cloudfunctions.net/getAudio?request=coran`
				)
				.then(function (res) {
					setListeCoran(res.data.listeAudio);
					localStorage.setItem(`liste_audio_coran`, JSON.stringify(res.data.listeAudio));
					localStorage.setItem(`liste_audio_storageDate_coran`, Date.now());
				})
				.catch(function (err) {
					console.log('error get Coran', err);
				});
		} else {
			setListeCoran(JSON.parse(localStorage.getItem(`liste_audio_coran`)));
		}
	};

	// Get Data
	useEffect(() => {
		// Si on détècte une liste dans le local storage
		if (JSON.parse(localStorage.getItem(`liste_audio_coran`))) {
			if (localStorage.getItem(`liste_audio_storageDate_coran`)) {
				// si la date existe
				const date = localStorage.getItem(`liste_audio_storageDate_coran`);
				checkLocalStorageAge(date);
			}
		} else {
			// console.log('start Get Audio')
			axios
				.get(
					`https://us-central1-dourousnet-3fcb0.cloudfunctions.net/getAudio?request=coran`
				)
				.then(function (res) {
					setListeCoran(res.data.listeAudio);
					localStorage.setItem(`liste_audio_coran`, JSON.stringify(res.data.listeAudio));
					localStorage.setItem(`liste_audio_storageDate_coran`, Date.now());
				})
				.catch(function (err) {
					console.log('error get Coran', err);
				});
		}
	}, []);

	// Création d'un tableau de catégories
	const categoriesCoran = [];
	if (listeCoran) {
		listeCoran.forEach(function (item) {
			if (categoriesCoran.length === 0) {
				categoriesCoran.push(item.theme);
			} else {
				// si deja inclus on retourne
				if (categoriesCoran.includes(item.theme) === true) {
					return;
				} else {
					// sinon on push le theme
					categoriesCoran.push(item.theme);
				}
			}
		});
	}

	// Select Sound
	const playSound = (url, title) => {
		const sound = {
			url: url,
			title: title
		};
		dispatch(audio(sound));
	};

	const [hasMore, setHasMore] = useState(true);
	const [current, setCurrent] = useState();
	const [loading, setLoading] = useState(false);
	const [ready, setReady] = useState(false);

	// Infinite Scroll Liste Audio
	const [count, setCount] = useState({
		prev: 0,
		next: 18
	});

	useEffect(() => {
		// if current Cat its ok
		if (currentCategorie) {
			setReady(true);
		}
	}, [currentCategorie]);

	useEffect(() => {
		if (currentCategorie) {
			setCurrent(
				listeCoran
					.filter(
						(el) =>
							el.theme
								.replace(/&rsquo;/g, "'")
								.replace(/&lsquo%3B/g, "'")
								.replace(/&lsquo;/g, "'")
								.replace(/&rsquo%3B/g, "'") === currentCategorie
					)
					.slice(count.prev, count.next)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listeCoran, currentCategorie, ready]);

	const getMoreData = () => {
		setLoading(true);
		if (
			current.length ===
			listeCoran.filter(
				(el) =>
					el.theme
						.replace(/&rsquo;/g, "'")
						.replace(/&lsquo%3B/g, "'")
						.replace(/&rsquo;/g, "'")
						.replace(/&lsquo;/g, "'")
						.replace(/&rsquo%3B/g, "'") === currentCategorie
			).length
		) {
			setHasMore(false);
			return;
		}
		setTimeout(() => {
			setCurrent(
				current.concat(
					listeCoran
						.filter(
							(el) =>
								el.theme
									.replace(/&rsquo;/g, "'")
									.replace(/&lsquo%3B/g, "'")
									.replace(/&rsquo;/g, "'")
									.replace(/&lsquo;/g, "'")
									.replace(/&rsquo%3B/g, "'") === currentCategorie
						)
						.slice(count.prev + 18, count.next + 18)
				)
			);
			setLoading(false);
		}, 1500);
		setCount((prevState) => ({ prev: prevState.prev + 18, next: prevState.next + 18 }));
	};

	const selectCategorie = (item) => {
		setCurrentCategorie(item);
		setHasMore(true);
		setLoading(false);
		setCount({
			prev: 0,
			next: 18
		});
		window.scrollTo(0, 0);
	};

	// Initialisation state change categorie
	const backToCategories = () => {
		window.scrollTo(0, 0);
		setCurrentCategorie(null);
		setHasMore(true);
		setLoading(false);
		setCount({
			prev: 0,
			next: 18
		});
	};

	let queryAudio;
	if (currentTitle) {
		if (listeCoran.length > 0) {
			listeCoran.forEach(function (item) {
				if (item.title === currentTitle) {
					queryAudio = (
						<div className="audio animate">
							<button
								onClick={() => playSound(item.mp3_url, item.title, item.date)}
								className={`h-full relative typoSecondary bg-primary hover:bg-secondary w-full flex flex-col items-center justify-center text-lg text-white px-4 py-16 rounded-2xl hover-ride-top-large animate mb-5`}
							>
								<div>{item.title}</div>
							</button>
						</div>
					);
				} else return;
			});
		}
	}

	let content;

	if (!currentCategorie) {
		if (categoriesCoran.length > 0) {
			content = (
				<div className="px-2 py-10 md:px-5 lg:p-10 md:p-0">
					<H2 className="mb-16">
						Récitateurs <span className="text-xxs">({categoriesCoran.length})</span>
					</H2>

					<div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-3 gap-y-14">
						{categoriesCoran.sort().map((item, index) => (
							<div key={index} className="audio">
								<Link
									to={`/coran?cat=${item.replace(/ /g, '+')}`}
									id="button-bg-gradient"
									onClick={() =>
										selectCategorie(
											item
												.replace(/&rsquo;/g, "'")
												.replace(/&lsquo%3B/g, "'")
												.replace(/&rsquo;/g, "'")
												.replace(/&lsquo;/g, "'")
												.replace(/&rsquo%3B/g, "'")
										)
									}
									className={`h-full relative typoSecondary bg-gradient hover:opacity-90 w-full flex items-center justify-center text-lg text-white px-4 py-16 rounded-2xl hover-ride-top-large animate`}
								>
									<div
										className="absolute z-0 w-full h-full opacity-70"
										style={{
											backgroundImage: `url(${flowers})`,
											backgroundSize: '170%'
										}}
									></div>
									<div className="w-full text-center">
										{item
											.replace(/&rsquo;/g, "'")
											.replace(/&lsquo%3B/g, "'")
											.replace(/&lsquo;/g, "'")
											.replace(/&rsquo%3B/g, "'")}
									</div>
								</Link>
							</div>
						))}
					</div>
				</div>
			);
		} else {
			content = <LoadingPlaceHolder />;
		}
	} else {
		content = (
			<div className="px-2 py-10 md:px-5 lg:p-10 md:p-0">
				<Link
					to={'/coran'}
					onClick={() => backToCategories()}
					className="text-secondary hover:text-primary text-xxs rounded-sm hover-ride-top-large animate mb-5 block"
				>
					<div className="flex items-center justify-center">
						<div className="mr-3">
							<svg
								className="h-5 w-5"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								strokeWidth="2"
								stroke="currentColor"
								fill="none"
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								<path stroke="none" d="M0 0h24v24H0z" />{' '}
								<line x1="5" y1="12" x2="19" y2="12" />{' '}
								<line x1="5" y1="12" x2="9" y2="16" />{' '}
								<line x1="5" y1="12" x2="9" y2="8" />
							</svg>
						</div>
						<div>changer de récitateur</div>
					</div>
				</Link>
				<div>
					<H2 className="mb-16">
						{currentCategorie
							.replace(/&rsquo;/g, "'")
							.replace(/&lsquo%3B/g, "'")
							.replace(/&lsquo;/g, "'")
							.replace(/&rsquo%3B/g, "'")}{' '}
						<span className="text-xxs">
							(
							{listeCoran && currentCategorie.length ? (
								listeCoran.filter(
									(el) =>
										el.theme
											.replace(/&rsquo;/g, "'")
											.replace(/&rsquo;/g, "'")
											.replace(/&lsquo;/g, "'")
											.replace(/&rsquo%3B/g, "'") === currentCategorie
								).length
							) : (
								<></>
							)}
							)
						</span>
					</H2>
					{listeCoran.length > 0 ? (
						<div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-3 gap-y-14">
							{current &&
								current.map((item, index) => (
									<div key={index} className="audio animate">
										<button
											onClick={() => playSound(item.mp3_url, item.title)}
											className={`h-full relative typoSecondary bg-primary hover:bg-secondary w-full flex flex-col items-center justify-center text-lg text-white px-4 py-16 rounded-2xl hover-ride-top-large animate`}
										>
											<div>
												{item.title
													.replace(/&rsquo;/g, "'")
													.replace(/&lsquo%3B/g, "'")
													.replace(/&lsquo;/g, "'")
													.replace(/&rsquo%3B/g, "'")}
											</div>
										</button>
									</div>
								))}
						</div>
					) : (
						<LoadingPlaceHolder />
					)}
				</div>
				{!loading ? (
					<button
						onClick={() => getMoreData()}
						className="mt-5 w-full text-secondary hover:text-primary uppercase animate font-semibold p-6"
					>
						charger plus ...
					</button>
				) : hasMore ? (
					<div className="flex items-center justify-center mt-5 w-full text-secondary uppercase animate font-semibold p-6">
						<Loader />
					</div>
				) : (
					<div className="flex items-center justify-center mt-5 w-full text-gray-400 uppercase animate p-6 text-xxs">
						Il n'y a plus d'audio dans cette catégorie
					</div>
				)}
			</div>
		);
	}

	return (
		<Layout title="Coran">
			<Seo title="Coran" />

			<Container>
				<ContainerWithSideBarre>
					<StickyBox
						offsetTop={20}
						offsetBottom={20}
						className="w-full lg:w-8/12 stickyBox"
					>
						<div>{queryAudio}</div>
						<ContentLeft>{content}</ContentLeft>
					</StickyBox>

					<StickyBox
						offsetTop={20}
						offsetBottom={20}
						className="w-full lg:w-4/12 stickyBox"
					>
						<ContentRight>
							<SideBar
								type="coran"
								categories={categoriesCoran}
								liste={listeCoran}
								selectCategorie={selectCategorie}
							/>
						</ContentRight>
					</StickyBox>
				</ContainerWithSideBarre>
			</Container>
		</Layout>
	);
};

export default Coran;
